import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Search from './search';
import User from './user';

const BarContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #000;
  display: flex;
  align-items: center;
`;

const Title = styled.span`
  padding: 0 32px;
  color: #fff;
  font-size: 26px;
  span {
    color: #ccc;
  }
  @media(max-width: 540px) {
    display: none;
  }
`;



class Bar extends Component {
  render = () => {
    const { search, logout } = this.props;
    const name = JSON.parse(window.localStorage.getItem('bookify')).name;
    return (
      <BarContainer>
        <Title>Booki<span>fy.</span></Title>
        <Search search={ search } />
        <User logout={ logout } name={ name } />
      </BarContainer>
    )
  }
}

Bar.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
};

export default Bar;
