import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

const FormCollectionContainer = styled.div`
  position: fixed;
  overflow: scroll;
  right: -330px;
  bottom: 0;
  top: 68px;
  border-left: 2px solid #000;
  padding: 20px 30px;
  background: #fff;
  max-width: 260px;
  transition: all 0.3s;
  &.open {
    right: 0;
  }
`;

const Text = styled.p`
  font-size: 18px;
  text-align: left;
  width: 100%;
  margin: 0 0 20px;
`;

const InputField = styled.input`
  width: 100%;
  padding: 0 12px;
  height: 36px;
  margin: 0 0 16px;
  border: 1px solid #000;
  box-sizing: border-box;
`;

const Button = styled.button`
  width: 100%;
  text-align: center;
  border: 0;
  background: #000;
  color: #fff;
  height: 36px;
  font-size: 16px;
  cursor: pointer;
  transtion: all 0.3s;
  &:hover {
    background: #ddd;
    color: #000;
  }
`;

const Close = styled.button`
  border: 0;
  background: none;
  color: #000;
  cursor: pointer;
  transition: all 0.3s;
  right: 30px;
  outline: none;
  top: 20px;
  line-height: 20px;
  position: absolute;
  &:hover {
    background: #000;
    color: #fff;
  }
`;

const FormCollection = ({ save, status, closeAction }) => {
  return (
    <FormCollectionContainer className={ status ? 'open' : '' }>
      <Text>Coleções</Text>
      <Close onClick={ closeAction }>
        <FontAwesomeIcon icon={ faTimes } />
      </Close>
      <form onSubmit={ save }>
        <InputField placeholder="Titulo" type="text" name="name" />
        <Button>Salvar</Button>
      </form>
    </FormCollectionContainer>
  )
}

export default FormCollection;
