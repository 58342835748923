import React from 'react';
import styled from 'styled-components';

const UserGreetingContainer = styled.div`
  padding: 30px 0;
  display: flex;
  width: 100%;
`;

const Name = styled.div`
  font-size: 20px;
  flex-grow: 1;
`;

const Button = styled.button`
  border: 0;
  padding: 0;
  cursor: pointer;
`;

const UserGreeting = ({ name, logout }) => {
  return (
    <UserGreetingContainer>
      <Name>{ name }</Name>
      <Button onClick={ logout }>sair</Button>
    </UserGreetingContainer>
  )
}

export default UserGreeting;
