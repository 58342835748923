import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

const SearchContainer = styled.div`
  padding: 16px 0;
  flex-grow: 1;
  position: relative;
  @media (max-width: 540px) {
    padding-left: 15px;
  }
`;

const Input = styled.input`
  height: 36px;
  padding: 0 12px;
  background: #fff;
  border: 0;
  font-size: 18px;
  width: 100%;
  transition: all 0.3s;
  outline: none;
  box-sizing: border-box;
  &:focus {
    background: #ccc;
  }
`;

const Icon = styled.i`
  position: absolute;
  right: 12px;
  font-size: 16px;
  line-height: 36px;
`;

const Search = ( { search }) => {
  return (
    <SearchContainer>
      <Input onChange={ search } type="text" placeholder="Procurar..." />
      <Icon>
        <FontAwesomeIcon icon={ faSearch } />
      </Icon>
    </SearchContainer>
  )
}

export default Search;
