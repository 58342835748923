import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const CreateAccountFormContainer = styled.div`
  display: flex;
  max-width: 250px;
  background: #fff;
  flex-wrap: wrap;
  padding: 20px 16px;
  position: relative;
  z-index: 4;
  @media (max-width: 540px) {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
  }
  a {
    color: #000;
    margin-top: 10px;
    display: block;
    text-decoration: none;
  }
`;

const Text = styled.p`
  font-size: 16px;
  text-align: center;
  width: 100%;
`;

const InputField = styled.input`
  width: 100%;
  padding: 0 12px;
  height: 36px;
  box-sizing: border-box;
  border: 1px solid #000;
  margin: 0 0 16px;
`;

const LinkContainer = styled.div`
  margin: 20px 0 0;
  padding: 20px 0 0;
  border-top: 1px solid #ddd;
  width: 100%;
`;

const Button = styled.button`
  width: 100%;
  text-align: center;
  border: 0;
  background: #000;
  color: #fff;
  height: 36px;
  font-size: 16px;
  cursor: pointer;
  transtion: all 0.3s;
  &:hover {
    background: #ddd;
    color: #000;
  }
`;

const Loading = styled.div`
  background: url(/assets/images/loading.svg) #fff no-repeat center;
  background-size: 100px auto;
  position: relative;
  z-index: 4;
  height: 353px;
  max-width: 250px;
  width: 100%;
`;

class CreateAccountForm extends Component {
  render = () => {
    const { create, isLoading } = this.props;
    return isLoading ? (
      <Loading />
    ) : (
      <CreateAccountFormContainer>
        <Text>Criar minha conta</Text>
        <form onSubmit={ create }>
          <InputField placeholder="Seu e-mail" type="email" name="email" />
          <InputField placeholder="Sua senha" type="password" name="pwd" />
          <InputField placeholder="Seu nome" type="text" name="name" />
          <Button>Começar a usar</Button>
        </form>
        <LinkContainer>
          <Link to="/">
            Voltar
          </Link>
        </LinkContainer>
      </CreateAccountFormContainer>
    )
  }
}

CreateAccountForm.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
};

export default CreateAccountForm;
