import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faShareAlt } from '@fortawesome/free-solid-svg-icons'

const CollectionsBarContainer = styled.div`
  padding: 20px 0;
  border-top: 1px solid #ccc;
  width: 100%;
  font-size: 14px;
`;

const CollectionItem = styled.div`
  display: flex;
  padding: 10px 0;
  transition: all 0.3s;
  cursor: pointer;
  align-items: center;
  &.active {
    background: #eee;
    padding-left: 10px;
  }
  span {
    flex-grow: 1;
  }
  button {
    border: 0;
    background: none;
    outline: none;
    cursor: pointer;
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 6px 0;
  border: 0;
  text-align: left;
  outline: none;
  cursor: pointer;
  &.big {
    font-size: 18px;
  }
  i {
    margin-right: 10px;
  }
`;

const Text = styled.p`
  font-size: 16px;
  font-weight: 700;
`;

const CollectionsBar = ({ id, selectedCollection, toggleSelection, collections, total, openForm }) => {
  return (
    <CollectionsBarContainer>
      <Text>Minhas coleções</Text>
      { collections.length > 1 && <CollectionItem className={ selectedCollection === 'all' && 'active' } onClick={ (e) => { toggleSelection(e, 'all') } }>Todas ({total})</CollectionItem> }
      {
        collections.map((item, index) => {
          return (
            <CollectionItem key={ index } className={ selectedCollection === item.id && 'active' }>
              <span onClick={ (e) => { toggleSelection(e, item.id) } }>
                { item.name } ({item.books})
              </span>
              {
                item.user_id === id && (
                  <button onClick={ (e) => { openForm(e, 'share', item.id) } }>
                    <FontAwesomeIcon icon={ faShareAlt } />
                  </button>
                )
              }
            </CollectionItem>
          )
        })
      }
      <Button onClick={ (e) => { openForm(e, 'collection') } } className={ collections.length < 1 ? 'big' : '' }>
        <i>
          <FontAwesomeIcon icon={ faPlus } />
        </i>
        Adicionar
      </Button>
    </CollectionsBarContainer>
  )
}

export default CollectionsBar;
