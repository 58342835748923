import React from 'react';
import styled from 'styled-components';
import Item from './item';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faSortAmountDown, faSortAmountUp, faArrowUp } from '@fortawesome/free-solid-svg-icons'

const ListContainer = styled.div`
  display: flex;
  padding: 0 15px;
  width: 100%;
  flex-grow: 1;
  &.empty {
    align-items: center;
  }
`;

const ListItems = styled.div`
  max-width: 800px;
  width: 100%;
  margin: 30px auto;
`;

const ListEnd = styled.div`
  border: 2px dashed #ccc;
  text-align: center;
  padding: 20px 0;
  color: #ccc;
  margin-top: 20px;
  &:after {
    content: 'Fim da lista';
  }
`;

const Button = styled.button`
  background: #bbb;
  color: #fff;
  padding: 10px 20px;
  border: 0;
  font-size: 16px;
  transition: all 0.3s;
  cursor: pointer;
  outline: none;
  &.big {
    max-width: 300px;
    width: 100%;
    margin: 40px auto;
    display: block;
  }
  &:hover {
    background: #000;
  }
  svg {
    margin-right: 10px;
  }
`;

const Text = styled.p`
  margin-left 17px;
  font-size: 24px;
  svg {
    margin-right: 10px;
  }
  &.center {
    text-align: center;
  }
`;

const TextSmall = styled.small`
  text-align: right;
  font-size: 14px;
  flex-grow: 1;
`;

const Loading = styled.div`
  background: url(/assets/images/loading.svg) no-repeat center;
  background-size: 100px auto;
  height: 100vh;
  width: 100vw;
`;

const UtilsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const List = ({ bottom, loadingList, collections, term, orderActiveRegular, toggleOrder, isSearch, countFilter, total, deleteItem, loadingCollection, newList, openForm, list }) => {
  return (loadingList || loadingCollection) ? (
    <Loading/>
  ): (
    <ListContainer className={ (list.length < 1 && term.length < 1)  && 'empty' }>
      <ListItems>
        <UtilsContainer>
          <Button onClick={ (e) => { openForm(e, 'book') } }>
            <FontAwesomeIcon icon={ faPlus } />
            Adicionar
          </Button>
          { list.length > 1 && <Button onClick={ toggleOrder }><FontAwesomeIcon icon={ orderActiveRegular ? faSortAmountDown : faSortAmountUp } /></Button> }
          { isSearch && <TextSmall>Mostrando { countFilter } de { total }</TextSmall>}
        </UtilsContainer>
        { (list.length < 1 && term.length < 1 && document.body.clientWidth > 789) && <Text><FontAwesomeIcon icon={ faArrowUp } />Comece adicionando um livro na sua coleção!</Text> }
        { (list.length < 1 && term.length < 1 && document.body.clientWidth <= 789) && <Text><FontAwesomeIcon icon={ faArrowUp } />Agora comece a adicionar seus livros clicando aqui</Text> }
        { (list.length < 1 && term.length > 0) && <Text className="center">Não achamos nada usando: <strong>{term}</strong></Text> }
        {
          list.map((item, key) => {
            return (
              <Item key={ key } showCollection={ collections.length > 1 } deleteItem={ deleteItem } edit={ openForm } {...item} />
            )
          })
        }
        { bottom && <ListEnd/> } 
      </ListItems>
    </ListContainer>
  )
}

export default List;
