import React from 'react';
import styled from 'styled-components';

import UserGreeting from './userGreeting';
import CollectionsBar from './collectionsBar';

const UserBarContainer = styled.div`
  max-width: 180px;
  padding: 0 32px;
  width: 100%;
`;

const UserBar = ({ user, selectedCollection, toggleSelection, openForm, total, logout, collections, }) => {
  return document.body.clientWidth > 789 && (
    <UserBarContainer>
      <UserGreeting { ...user } logout={ logout } />
      <CollectionsBar { ...user } selectedCollection={ selectedCollection } toggleSelection={ toggleSelection } openForm={ openForm } total={ total } collections={ collections } />
    </UserBarContainer>
  )
}

export default UserBar;
