import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import Moment from 'react-moment';

const ItemContainer = styled.div`
  background: #fff;
  border: 2px solid #ccc;
  padding: 20px;
  display: flex;
  width: 100%;
  margin-top: 20px;
  box-sizing: border-box;
`;

const Content = styled.div`
  flex-grow: 1;
`;

const Title = styled.strong`
  font-size: 22px;
`;

const Author = styled.span`
  font-size: 16px;
  line-height: 22px;
  &:before { 
    content: ', ';
  }
`;

const Utils = styled.div`
  border-top: 1px solid #ccc;
  width: 100%;
`;

const CreatedAt = styled.small`
  font-size: 12px;
`;

const Button = styled.button`
  border: 0;
  padding: 6px 12px;
  cursor: pointer;
  outline: none;
  transition: all 0.3s;
  background: none;
  svg {
    margin-right: 10px;
  }
  @media (max-width: 540px) {
    svg {
      margin: 0;
    }
    span {
      display: none;
      
    }
  }
`;

const Info = styled.p`
  font-size: 14px;
`;

const Catalog = styled.pre`
  font-size: 14px;
  font-family: Roboto;
`;

const Piece = styled.span`
  margin-left: 10px;
  &:first-child {
    margin-left: 0;
  }
`;

class Item extends Component {
  render = () => {
    const { edit, deleteItem, showCollection, edition, collection, author, name, id, date, catalog, subject, created_at } = this.props;
    return (
      <ItemContainer>
        <Content>
          <Title>{ name }</Title>
          { author && <Author>{ author }</Author> }
          <Info>
            { edition && (<Piece>Edição: { edition }</Piece> ) }
            { date && (<Piece>Ano: { date }</Piece> ) }
            { subject && (<Piece>Assunto: { subject }</Piece> ) }
            { showCollection && (<Piece>Coleção: { collection }</Piece> ) }
          </Info>
          { catalog && (<Catalog>
            <Piece>Catalogo:<br/>{ catalog }</Piece> 
          </Catalog>) }
          <Utils>
            <CreatedAt>
              <Moment fromNow>{ created_at }</Moment>
            </CreatedAt> - 
            <Button onClick={ (e) => { edit(e, 'book', id) } }>
              <FontAwesomeIcon icon={ faEdit } />
              <span>Editar</span>
            </Button> -
            <Button onClick={ (e) => { deleteItem(e, id) } }>
              <FontAwesomeIcon icon={ faTrash } />
              <span>Deletar</span>
            </Button>
          </Utils>
        </Content>
      </ItemContainer>
    )
  }
}

Item.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
};

export default Item;
