import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './styles/main.scss';

import Home from './containers/home/home';
import Collection from './containers/collection/collection';
import CreateAccount from './containers/create-account/createAccount';

const App = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  window.api = '//bookify.nearfuturelab.com.br/';
  return (
    <Router>
      <Switch>
        <Route exact={ true } path="/" component={ Home } />
        <Route exact={ true } path="/criar-minha-conta" component={ CreateAccount } />
        <Route exact={ true } path="/colecao" component={ Collection } />
      </Switch>
    </Router>
  );
}

export default App;
