import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faTrash } from '@fortawesome/free-solid-svg-icons'

const FormShareContainer = styled.div`
  position: fixed;
  overflow: scroll;
  right: -330px;
  bottom: 0;
  top: 68px;
  border-left: 2px solid #000;
  padding: 20px 30px;
  background: #fff;
  max-width: 260px;
  width: 100%;
  transition: all 0.3s;
  &.open {
    right: 0;
  }
  form {
    position: relative;
  }
`;

const Text = styled.p`
  font-size: 18px;
  text-align: left;
  width: 100%;
  margin: 0 0 20px;
`;

const InputField = styled.input`
  width: 100%;
  padding: 0 12px;
  height: 36px;
  margin: 0 0 16px;
  border: 1px solid #000;
  outline: none;
  box-sizing: border-box;
`;

const Close = styled.button`
  border: 0;
  background: none;
  color: #000;
  cursor: pointer;
  transition: all 0.3s;
  right: 30px;
  outline: none;
  top: 20px;
  line-height: 20px;
  position: absolute;
  &:hover {
    background: #000;
    color: #fff;
  }
`;

const List = styled.div`
  position: absolute;
  top: 32px;
  left: 0;
  right: 0;
  background: #fff;
  border: 1px solid #000;
  border-top: 1px solid #fff;
  div {
    cursor: pointer;
    padding: 10px 12px;
    &:hover {
      background: #eee;
    }
    span,
    small {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 240px;
    }
  }
`;

const Button = styled.button`
  width: 100%;
  text-align: center;
  border: 0;
  background: #000;
  color: #fff;
  height: 36px;
  font-size: 16px;
  cursor: pointer;
  transtion: all 0.3s;
  &:hover {
    background: #ddd;
    color: #000;
  }
`;

const Users = styled.div`
  margin-bottom: 20px;
  &.margin {
    margin: 20px 0 0;
  }
  div {
    padding: 10px 0;
    border-top: 1px solid #000;
    display: flex;
    align-items: center;
    &:first-child {
      border-top: 0;
    }
    div {
      flex-grow: 1;
      flex-wrap: wrap;
      padding: 0;
    }
    button {
      border: 0;
      background: 0;
      cursor: pointer;
      outline: none;
    }
    span,
    small {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 240px;
    }
  }
`;

class FormShare extends Component {
  state = { 
    listUsers: []
  }

  render = () => {
    const { collection, addUser, removeUser, removeUserCollection, searchTerm, listSelectedUsers, save, status, closeAction, search, list, } = this.props;
    return (
      <FormShareContainer className={ status ? 'open' : '' }>
        <Text>Compartilhar</Text>
        <Close onClick={ (e) => { closeAction(e, 'share') } }>
          <FontAwesomeIcon icon={ faTimes } />
        </Close>
        <form onSubmit={ save }>
          { (collection && collection.id) && <input type="hidden" name="collection_id" value={ collection.id } /> }
          <InputField autoComplete="off" value={ searchTerm } placeholder="E-mail" onChange={ search } />
          { (list && list.length > 0) && 
            <List>
              {
                list.map((item, index) => {
                  return (
                    <div key={ index } onClick={ (e) => { addUser(e, item) } }>
                      <span>{item.name}</span> 
                      <small>&lt;{item.email}&gt;</small>
                    </div>
                  )
                })
              }
            </List> 
          }
          <Users>
            {
              listSelectedUsers.map((item, index) => {
                return item && (
                  <div key={ index }>
                    <div>
                      <input type="hidden" name="id[]" value={ item.id } />
                      <span>{item.name}</span> 
                      <small>&lt;{item.email}&gt;</small>
                    </div>
                    <button onClick={ (e) => { removeUser(e, index, collection.id) } }>
                      <FontAwesomeIcon icon={ faTrash } />
                    </button>
                  </div>
                )
              })
            }
          </Users>  
          <Button>Salvar</Button>
        </form>
        <Users className="margin">
          {
            (collection && collection.users) && collection.users.map((item, index) => {
              return (
                <div key={ index }>
                  <span>{item.name}</span> 
                  <small>&lt;{item.email}&gt;</small>
                  <button onClick={ (e) => { removeUserCollection(e, item.id, collection.id) } }>
                    <FontAwesomeIcon icon={ faTrash } />
                  </button>
                </div>
              )
            })
          }
        </Users>
      </FormShareContainer>
    )
  } 
}

FormShare.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
};

export default FormShare;
