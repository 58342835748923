import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

import Bar from './../../components/bar/bar';
import List from './list';
import UserBar from './bar/userBar';
import FormBook from './forms/book';
import FormCollection from './forms/collection';
import FormShare from './forms/share';

const CollectionContainer = styled.div`
`;

const Container = styled.div`
  display: flex;
  min-height: calc(100vh - 68px);
  padding-top: 68px;
  &.center {
    align-items: center;
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 10px 40px;
  text-align: center;
  border: 0;
  background: none;
  height: 30px;
  font-size: 18px;
`;

const Text = styled.p`
  font-size: 18px;
  text-align: center;
  margin-top: 162px;
  svg {
    margin-right: 20px;
  }
`;

class Collection extends Component {
  state = {
    user: {},
    list: [],
    total: 0,
    page: 0,
    bottom: false,
    formStatusBook: false,
    formStatusCollection: false,
    formStatusShare: false,
    collections: [],
    selectedBook: {},
    selectedCollection: 'all',
    newList: false,
    newCollection: false,
    loadingList: true,
    loadingCollection: true,
    timerSearch: undefined,
    timerSearchUser: undefined,
    term: '',
    isSearch: false,
    countFilter: 0,
    orderActiveRegular: true,
    order: 'desc',
    searchUser: '',
    userList: '',
    selectedUserList: [],
    collectionShare: {},
    userSearchTerm: '',
  }

  componentWillMount = () => {
    const localStorage = window.localStorage.getItem('bookify');
    if (localStorage && localStorage.length < 1) {
      this.props.history.push('/');
    }
    this.setState( { user: JSON.parse(localStorage) } );
    this.getList();
    this.getCollection();
    window.addEventListener("scroll", this.handleScroll);
  }

  handleScroll = (event) => {
    console.log(this.state.bottom, ((window.scrollY + window.innerHeight - (window.innerHeight * 0.2)) >= window.document.body.clientHeight))
    if (!this.state.bottom && ((window.scrollY + window.innerHeight) >= (window.document.body.clientHeight - (window.innerHeight * 0.2)))) {
      this.setState({page: this.state.page + 1})
      if (this.state.term.length > 0) {
        this.search();
      } else {
        this.getList();
      }
    }
  };

  getList = async (attr) => {
    const fetchOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    };
    const id = JSON.parse(window.localStorage.getItem('bookify')).id;
    let order = this.state.order;
    if (attr && attr.order)
      order = attr.order;
    let url = `books/${id}/all/${this.state.page}/${order}/`;
    if ((attr && attr.collection && attr.collection !== 'all') || (this.state.selectedCollection !== 'all')) {
      let collection = this.state.selectedCollection;
      if (attr && attr.collection)
        collection = attr.collection
      url = `books/${collection}/${this.state.page}/${order}/`;
    }
    const resp = await fetch(window.api + url, fetchOptions)
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      })
      .catch((e) => {
        return false;
      });
    if (resp) {
      const list = [...this.state.list, ...resp.list];
      this.setState({ list: list, loadingList: false, bottom: list.length === resp.total });
    } else {
      this.setState({ loadingList: false });
    }
    return;
  }

  getCollection = async (attr) => {
    const fetchOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    };
    const id = JSON.parse(window.localStorage.getItem('bookify')).id;
    const resp = await fetch(window.api + `collections/${id}/`, fetchOptions)
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      })
      .catch((e) => {
        return false;
      });
    if (resp) {
      let total = 0;
      resp.map((item) => {
        total += item.books;
        return true;
      })
      let selectedCollection = 'all';
      if (resp.length === 1) {
        selectedCollection = resp[0].id;
      }
      this.setState({ total: total, collections: resp, selectedCollection: selectedCollection, newCollection: false, loadingCollection: false });
      if (attr && attr.collectionShare) {
        const list = resp.filter((item) => item.id === parseInt(attr.collection))[0];
        console.log(attr, list, resp);
        this.setState({ collectionShare: list });
      }
    } else {
      this.setState({ newCollection: true, loadingCollection: false });
    }
    return;
  }

  logout = () => {
    window.localStorage.setItem('bookify', '');
    this.props.history.push('/');
  }

  closeForm = (e, type) => {
    switch (type) {
      case 'book':
        this.setState({ selected: {} });
        this.setState({ formStatusBook: false });
        break;
      case 'collection':
        this.setState({ formStatusCollection: false });
        break;
      case 'share':
        this.setState({ formStatusShare: false });
        break;
      default:
    }
    return;
  }

  openForm = (e, type, id) => {
    switch (type) {
      case 'book':
        if (id) {
          const selectedBook = this.state.list.filter((item) => item.id === id)[0];
          this.setState({ selectedBook: selectedBook });
        }
        this.setState({ formStatusBook: true });
        break;
      case 'collection':
        this.setState({ formStatusCollection: true });
        break;
      case 'share':
        const list = this.state.collections.filter((item) => item.id === id)[0];
        this.setState({ formStatusShare: true, collectionShare: list });
        break;
      default:
    }
    return;
  }

  deleteItem = async (e, id) => {
    if (window.confirm('Deseja deletar esse livro?')) {
      const fetchOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      };
      const resp = await fetch(window.api + `books/${this.state.user.id}/delete/${id}/`, fetchOptions)
        .then((response) => response.json())
        .then((responseJson) => {
          return responseJson.status;
        })
        .catch((e) => {
          return false;
        });
      if (resp) {
        this.getList();
        this.getCollection();
      } else {
        window.alert('Livro não encontrado');
      }
      return;
    }
  }

  saveShare = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const fetchOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        'users': this.state.selectedUserList,
      }),
    };
    const collection = data.get('collection_id');
    await fetch(window.api + `collections/${collection}/users/add/`, fetchOptions);
    this.getCollection({ collectionShare: true, collection: collection });
    this.setState( { selectedUserList: [] })
    return;
  }

  removeUserCollection = async (e, id, collection_id) => {
    e.preventDefault();
    const fetchOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }
    await fetch(window.api + `collections/${collection_id}/users/delete/${id}/`, fetchOptions);
    this.getCollection({ collectionShare: true, collection: collection_id });
    return;
  }

  saveBook = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const fetchOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        'name': data.get('name'),
        'author': data.get('author'),
        'date': data.get('date'),
        'edition': data.get('edition'),
        'catalog': data.get('catalog'),
        'subject': data.get('subject'),
      }),
    };
    let collection = this.state.collections[0].id;
    if (this.state.collections.length > 1) {
      collection = this.state.selectedBook.collection;
    }
    e.target.reset();
    let url = `books/${collection}/create/`
    if (this.state.selectedBook.id) {
      url = `books/${collection}/update/${this.state.selectedBook.id}/`
    }
    const resp = await fetch(window.api + url, fetchOptions)
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson.status;
      })
      .catch((e) => {
        return false;
      });
    if (resp) {
      if (this.state.term.length > 0) {
        this.search();
      } else {
        this.getList();
      }
      this.getCollection();
      if (this.state.selectedBook.id) {
        this.closeForm(e, 'book');
      }
      this.setState({ selectedBook: {}});
    } else {
      window.alert('Algo deu errado, tente novamente mais tarde.');
    }
    return;
  }

  saveCollection = async (e) => {
    e.preventDefault();
    const id = JSON.parse(window.localStorage.getItem('bookify')).id;
    const data = new FormData(e.target);
    const fetchOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        'name': data.get('name'),
      }),
    };
    e.target.reset();
    const resp = await fetch(window.api + `collections/${id}/create/`, fetchOptions)
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson.status;
      })
      .catch((e) => {
        return false;
      });
    if (resp) {
      this.getList();
      this.getCollection();
      this.setState({ selectedCollection: {}, formStatusCollection: false});
    } else {
      window.alert('Algo deu errado, tente novamente mais tarde.');
    }
    return;
  }

  setValue = (e) => {
    const selected = this.state.selectedBook;
    selected[e.target.name] = e.target.value;
    this.setState( { selectedBook: selected } );
  }

  search = (e) => {
    clearTimeout(this.state.timerSearch);
    if ((!e && this.state.term) || e.target.value.length > 0) {
      if (e)
        this.setState({bottom: false, loadingList: true, list: [], term: e.target.value, page: 0});
      this.setState({
        timerSearch: setTimeout(() => {
          const fetchOptions = {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            }
          };
          const id = JSON.parse(window.localStorage.getItem('bookify')).id;
          fetch(window.api + `books/${id}/search/${this.state.term}/${this.state.page}/${this.state.order}/`, fetchOptions)
            .then((response) => response.json())
            .then((responseJson) => {
              const list = [...this.state.list, ...responseJson.list];
              this.setState({ list: list, loadingList: false, countFilter: responseJson.total, bottom: list.length === responseJson.total });
              return;
            })
            .catch((e) => {
              return false;
            });
          this.setState({ isSearch: true });
        }, 500)
      })
    } else {
      this.setState({ list: [], loadingList: true, isSearch: false });
      this.getList();
    }
    return;
  }

  toggleOrder = () => {
    const order = !this.state.orderActiveRegular;
    this.setState({ list: [], loadingList: true, order: order ? 'desc' : 'asc', orderActiveRegular: order })
    this.getList({order: order ? 'desc' : 'asc'});
  }

  toggleSelection = (e, collection) => {
    this.setState({ selectedCollection: collection, page: 0, list: [] });
    this.getList({collection: collection});
  }

  searchUser = (e) => {
    this.setState({searchUser: e.target.value});
    clearTimeout(this.state.timerSearchUser);
    this.setState({
      timerSearchUser: setTimeout(() => {
        this.setState({ userList: [] });
        const fetchOptions = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        };
        fetch(window.api + `users/search/${this.state.searchUser}/`, fetchOptions)
          .then((response) => response.json())
          .then((responseJson) => {
            this.setState({ userList: responseJson });
            return;
          })
          .catch((e) => {
            return false;
          });
      }, 500)
    })
  }

  addSelectUser = (e, user) => {
    const list = [...this.state.selectedUserList, user];
    this.setState({ selectedUserList: list, userList: [], searchUser: '' });
  }

  removeSelectUser = (e, index) => {
    e.preventDefault();
    const list = this.state.selectedUserList;
    delete list[index];
    this.setState({selectedUserList: list});
  }

  render = () => {
    return (
      <CollectionContainer>
        <Bar search={ this.search } logout={ this.logout } />
        <Container className={ (this.state.collections.length < 1 && document.body.clientWidth <= 789) && 'center'}>
          <UserBar toggleSelection={ this.toggleSelection } openForm={ this.openForm } { ...this.state } logout={ this.logout } />
          { this.state.collections.length > 0 && ( <List {...this.state} toggleOrder={ this.toggleOrder } deleteItem={ this.deleteItem } openForm={ this.openForm } /> ) }
          { (!this.state.loadingList && this.state.collections.length < 1 && document.body.clientWidth > 789) && ( <Text><FontAwesomeIcon icon={ faArrowLeft } />Crie uma coleção, e comece a organizar seus livros!</Text> ) }
          { (!this.state.loadingList && this.state.collections.length < 1 && document.body.clientWidth <= 789) && ( <Button onClick={ (e) => { this.openForm(e, 'collection') } }>Olá!<br/>Para começar cria uma coleção clicando aqui.</Button> ) }
          <FormBook collections={ this.state.collections } setValue={ this.setValue } {...this.state.selectedBook} save={ this.saveBook } status={ this.state.formStatusBook } closeAction={ this.closeForm } />
          <FormCollection setValue={ this.setValue } {...this.state.selectedCollection} save={ this.saveCollection } status={ this.state.formStatusCollection } closeAction={ this.closeForm } />
          <FormShare removeUserCollection={ this.removeUserCollection } listSelectedUsers={ this.state.selectedUserList } removeUser={ this.removeSelectUser } addUser={ this.addSelectUser } collection={ this.state.collectionShare } search={ this.searchUser } searchTerm={ this.state.searchUser } list={ this.state.userList } save={ this.saveShare } status={ this.state.formStatusShare } closeAction={ this.closeForm } />
        </Container>
      </CollectionContainer>
    )
  }
}

Collection.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
};

export default Collection;
