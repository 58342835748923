import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import LoginForm from './loginForm';

const HomeContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100vh;
  padding: 0 30px;
  @media (max-width: 540px) {
    flex-wrap: wrap;
    height: calc(var(--vh, 1vh) * 100);
  }
`;

const Title = styled.div`
  flex-grow: 1;
  position: relative;
  z-index: 4;
  color: #fff;
  @media (max-width: 540px) {
    width: 100%;
    text-align: center;
    margin: 20px 0;
  }
  strong {
    font-size: 32px;
  }
  span {
    color: #ddd;
  }
`;

const Slide = styled.div`
  filter: blur(2px) grayscale(40%);
  background: url(/assets/images/banner-home-1.jpg) no-repeat;
  background-size: cover;
  position: fixed;
  top: -20px;
  left: -20px;
  bottom: -20px;
  right: -20px;
  z-index: 1;
  &:after {
    content: ' ';
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
`;

class Home extends Component {
  state = {
    isLoading: false
  }
  
  componentWillMount = () => {
    const localStorage = window.localStorage.getItem('bookify');
    if (localStorage && localStorage.length > 0) {
      if (JSON.parse(localStorage).id) {
        this.props.history.push('/colecao');
      }
    }
  }

  login = async (e) => {
    this.setState({isLoading: true});
    e.preventDefault();
    const data = new FormData(e.target);
    const fetchOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({'email': data.get('email'), 'pwd': data.get('pwd')}),
    };
    e.target.reset();
    const resp = await fetch(window.api + 'user/login/', fetchOptions)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          window.localStorage.setItem('bookify', JSON.stringify({'id': responseJson.id, 'name': responseJson.name}));
          return true;
        } else {
          return false;
        }
      })
      .catch((e) => {
        return false;
      });
    if (resp) {
      this.props.history.push('/colecao');
    } else {
      alert('Usuário não encontrado');
    }
    this.setState({isLoading: false});
  }

  render = () => {
    return (
      <HomeContainer>
        <Slide></Slide>
        <Title>
          <div>
            <strong>Booki<span>fy.</span></strong>
            <p>Organize.</p>
          </div>
        </Title>
        <LoginForm {...this.state} login={ this.login } />
      </HomeContainer>
    )
  }
}

Home.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
};

export default Home;
