import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

const FormBookContainer = styled.div`
  position: fixed;
  overflow: scroll;
  right: -330px;
  bottom: 0;
  top: 68px;
  border-left: 2px solid #000;
  padding: 20px 30px;
  background: #fff;
  max-width: 260px;
  transition: all 0.3s;
  &.open {
    right: 0;
  }
`;

const Text = styled.p`
  font-size: 18px;
  text-align: left;
  width: 100%;
  margin: 0 0 20px;
`;

const InputField = styled.input`
  width: 100%;
  padding: 0 12px;
  height: 36px;
  margin: 0 0 16px;
  border: 1px solid #000;
  box-sizing: border-box;
`;

const TextareaField = styled.textarea`
  width: 100%;
  padding: 12px 12px;
  margin: 0 0 16px;
  border: 1px solid #000;
  box-sizing: border-box;
  height: 160px;
`;

const SelectField = styled.select`
  width: 100%;
  padding: 0 12px;
  height: 36px;
  margin: 0 0 16px;
  border: 1px solid #000;
  box-sizing: border-box;
`;

const Button = styled.button`
  width: 100%;
  text-align: center;
  border: 0;
  background: #000;
  color: #fff;
  height: 36px;
  font-size: 16px;
  cursor: pointer;
  transtion: all 0.3s;
  &:hover {
    background: #ddd;
    color: #000;
  }
`;

const Close = styled.button`
  border: 0;
  background: none;
  color: #000;
  cursor: pointer;
  transition: all 0.3s;
  right: 30px;
  outline: none;
  top: 20px;
  line-height: 20px;
  position: absolute;
  &:hover {
    background: #000;
    color: #fff;
  }
`;

const FormBook = ({ save, collections, status, closeAction, name, author, date, edition, setValue, catalog, subject }) => {
  return (
    <FormBookContainer className={ status ? 'open' : '' }>
      <Text>Livros</Text>
      <Close onClick={ (e) => { closeAction(e, 'book') } }>
        <FontAwesomeIcon icon={ faTimes } />
      </Close>
      <form onSubmit={ save }>
        { collections.length > 1 && (
          <SelectField name="collection" onChange={ setValue }>
            <option value="">Selecione uma coleção</option>
            { collections.map((item, index) => {
              return (
                <option key={ index } value={ item.id }>{item.name}</option> 
              )
            })}
          </SelectField>
        )}
        <InputField placeholder="Titulo" value={ name ? name : '' } onChange={ setValue } type="text" name="name" />
        <InputField placeholder="Author" value={ author ? author : '' } onChange={ setValue } type="text" name="author" />
        <InputField placeholder="Data" value={ date ? date : '' } onChange={ setValue } type="text" name="date" />
        <InputField placeholder="Edição" value={ edition ? edition : '' } onChange={ setValue } type="text" name="edition" />
        <TextareaField placeholder="Catalogo" value={ catalog ? catalog : '' } onChange={ setValue } type="text" name="catalog" />
        <InputField placeholder="Assunto" value={ subject ? subject : '' } onChange={ setValue } type="text" name="subject" />
        <Button>Salvar</Button>
      </form>
    </FormBookContainer>
  )
}

export default FormBook;
