import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faCogs, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

const UserContainer = styled.div`
  padding: 0 32px;
  font-size: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 68px;
  &:hover div {
    display: block;
  }
`;

const UserName = styled.span`
  font-size: 18px;
  color: #fff;
`;

const Options = styled.div`
  position: absolute;
  background: #fff;
  display: none;
  border: 2px solid #000;
  padding: 10px 0;
  right: -2px;
  top: 66px;
  a {
    text-decoration: none;
    display: block;
    padding: 6px 20px;
    color: #000;
  }
`;

const Icon = styled.i`
  padding-left: 16px;
  font-size: 18px;
  color: #fff;
`;

const IconSmall = styled.i`
  padding-right: 16px;
  font-size: 14px;
  color: #000;
`;


const User = ({ name, logout }) => {
  return (
    <UserContainer>
      <UserName>{ name }</UserName>
      <Icon>
        <FontAwesomeIcon icon={ faBars } />
      </Icon>
      <Options>
        <Link to="/configuracoes">
          <IconSmall>
            <FontAwesomeIcon icon={ faCogs } />
          </IconSmall>
          Configurações
        </Link>
        <Link onClick={ logout } to="/">
          <IconSmall>
            <FontAwesomeIcon icon={ faSignOutAlt } />
          </IconSmall>
          Sair
        </Link>
      </Options>
    </UserContainer>
  )
}

export default User;
